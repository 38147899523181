export const formTemplate = {
  mainpage: {
    country: '',
    salesOrg: '',
    companyCode: '',
    customerReqType: '',
    shipToType: '',
    m1: '',
    m2: '',
  },
  shipto: {
    promoNews: '',
    beesRelevantFlag: '',
    existingShipToID: '',
    name: '',
    name2: '',
    postalCode: '',
    street: '',
    houseNumber: '',
    city: '',
    region: '',
    regionDesc: '',
    language: '',
    langDesc: '',
    telephoneOption: '',
    generalTelephone: '',
    logTelephone: '',
    invTelephone: '',
    orderTelephone: '',
    mobile: '',
    email: '',
    contactPerson: '',
    profitCenter: '',
    ediOption: '',
    glnIlnCode: '',
    ediEmail: '',
    ediType: '',
    segment: '',
    segmentDesc: '',
    subSegment: '',
    subSegmentDesc: '',
    exciseDutyType: '',
    exciseDutyTypeDesc: '',
    pocSupplierShipTo: '',
    pocPayer: '',
    pocPriceList: '',
    deliveringPlant: '',
    deliveringPlantDesc: '',
    delivWindow: '',
    dNodeCpt: '',
    aNode: '',
    shippingCondition: '',
    shippingConditionDesc: '',
    hierarchyTypeDNode: '',
    subChannel: '',
    transportationZone: '',
    transZDesc: '',
    tier: '',
    customerClassification: '',
    monMornFrom: '',
    monMornTo: '',
    monAftFrom: '',
    monAftTo: '',
    tueMornFrom: '',
    tueMornTo: '',
    tueAftFrom: '',
    tueAftTo: '',
    wedMornFrom: '',
    wedMornTo: '',
    wedAftFrom: '',
    wedAftTo: '',
    thuMornFrom: '',
    thuMornTo: '',
    thuAftFrom: '',
    thuAftTo: '',
    friMornFrom: '',
    friMornTo: '',
    friAftFrom: '',
    friAftTo: '',
    satMornFrom: '',
    satMornTo: '',
    satAftFrom: '',
    satAftTo: '',
    sunMornFrom: '',
    sunMornTo: '',
    sunAftFrom: '',
    sunAftTo: '',
    taxWarehouseNumber: '',
    warehouseExternalEdNumber: '',
    truckType: '',
    truckCode: '',
    truckDesc: '',
    trailerType: '',
    trailerCode: '',
    trailerDesc: '',
    ldeOption: '',
    ldeType: '',
  },
  payer: {
    creditBlock: '',
    existingPayerID: '',
    ReasonPaymentTerm: '',
    title: '',
    titleDesc: '',
    taxNumber: '',
    taxNumber2: '',
    vatRegNumber: '',
    name: '',
    name2: '',
    companyNumber: '',
    postalCode: '',
    houseNumber: '',
    street: '',
    city: '',
    payerCountry: '',
    region: '',
    regionCode: '',
    language: '',
    langDesc: '',
    telephone: '',
    mobile: '',
    email: '',
    yearlyVolForecastHL: '',
    contactPerson: '',
    eInvOption: '',
    eInvEmailType: '',
    eInvGeneralEmail: '',
    eInvLogEmail: '',
    eInvInvEmail: '',
    eInvOrderEmail: '',
    isVendor: '',
    vendorNumber: '',
    ediOption: '',
    glnIlnCode: '',
    ediEmail: '',
    ediType: '',
    forecastVolumeHL: '',
    iban: '',
    accHolderName: '',
    bankKey: '',
    bankAcc: '',
    swiftBicCode: '',
    supportDocsBank: '',
    supportDocsBankName: '',
    supportDocsBank1: '',
    supportDocsBankName1: '',
    supportDocsBank2: '',
    supportDocsBankName2: '',
    supportDocsBank3: '',
    supportDocsBankName3: '',
    supportDocsBank4: '',
    supportDocsBankName4: '',
    supportDocsBank5: '',
    supportDocsBankName5: '',
    supportDocsBank6: '',
    supportDocsBankName6: '',
    supportNoVAT: '',
    supportNoVATName: '',
    paymentTerms: '',
    paymentTermsDesc: '',
    onInvoicingDiscount: '',
    regulator: '',
    riskCategory: '',
    rent: '',
    sdiCode: '',
    paymentMethod: '',
    reasonDirectDebit: '',
  },
  soldto: {
    vatRegNumber: '',
    attributeType9: '',
    hierarchyTypeANode: '',
    ediOption: '',
    glnIlnCode: '',
    ediType: '',
    ediEmailAddress: '',
    incoTerms1: '',
    incoTerms2: '',
    hierarchyNumber: '',
    aNode: '',
    priceList: '',
    dropSize: '',
    promoNews: '',
    promoFlag: '',
    verwerkingstoeslag: '',
    deliveryDay: '',
    att7: '',
    soldToPayer2: '',
    pricingHierarchy: '',
    pricingHierarchyDesc: '',
    pricingHierarchyOpt: '',
    shippingCondition: '',
    name: '',
    name1: '',
    postalCode: '',
    houseNumber: '',
    street: '',
    city: '',
    soldCountry: '',
    region: '',
    regionDesc: '',
    language: '',
    telephone: '',
    mobile: '',
    email: '',
    merger: '',
    buyingGroup: '',
    conditionGroup2: '',
    customerGroup3Desc: '',
    conditionGroup2Desc: '',
    customerGroup3: '',
    ldeOption: '',
    ldeType: '',
  },
  billto: {
    vatRegNumber: '',
    taxNumber: '',
    taxNumber2: '',
    showBillTo: '',
    existingBillToID: '',
    title: '',
    name: '',
    postalCode: '',
    houseNumber: '',
    street: '',
    city: '',
    billCountry: '',
    region: '',
    regionDesc: '',
    language: '',
    telephone: '',
    mobile: '',
    emailAddress: '',
    eInvOption: '',
    eInvEmail: '',
    ediOption: '',
    glnIlnCode: '',
    ediEmail: '',
    ediType: '',
  },
};

import { useState, useEffect } from 'react';

export function useFormFields(initialStage) {
  const [fields, setValues] = useState(initialStage);

  const [selectedFile, setSelectedFile] = useState([]);

  const [fileB64Str, setFileB64Str] = useState('');
  const [fileName, setFileName] = useState('');

  const [fileB64Str1, setFileB64Str1] = useState('');
  const [fileName1, setFileName1] = useState('');

  const [fileB64Str2, setFileB64Str2] = useState('');
  const [fileName2, setFileName2] = useState('');

  const [fileB64Str3, setFileB64Str3] = useState('');
  const [fileName3, setFileName3] = useState('');

  const [fileB64Str4, setFileB64Str4] = useState('');
  const [fileName4, setFileName4] = useState('');

  const [fileB64Str5, setFileB64Str5] = useState('');
  const [fileName5, setFileName5] = useState('');

  const [fileB64Str6, setFileB64Str6] = useState('');
  const [fileName6, setFileName6] = useState('');

  const [fileB64Str7, setFileB64Str7] = useState('');
  const [fileName7, setFileName7] = useState('');

  const country = JSON.parse(sessionStorage.getItem('createCustomerMainPage')).country;

  const encodeFileB64 = (file, link) => {
    var reader = new FileReader();
    if (file) {
      if (file.size < 1000000) {
        reader.readAsDataURL(file);
        reader.onload = () => {
          var b64 = reader.result;
          console.log(`B64 = ${b64}`);
          console.log(file);
          setFileB64Str(b64);
          setFileName(file.name);
        };
        reader.onerror = (err) => {
          console.log(`Error: ${err}`);
        };
      }
    }
  };

  const encodeFile1B64 = (file, link) => {
    var reader = new FileReader();
    if (file) {
      if (file.size < 1000000) {
        reader.readAsDataURL(file);
        reader.onload = () => {
          var b64 = reader.result;
          console.log(`B64 = ${b64}`);
          console.log(file);
          setFileB64Str1(b64);
          setFileName1(file.name);
        };
        reader.onerror = (err) => {
          console.log(`Error: ${err}`);
        };
      }
    }
  };

  const encodeFile2B64 = (file, link) => {
    var reader = new FileReader();
    if (file) {
      if (file.size < 1000000) {
        reader.readAsDataURL(file);
        reader.onload = () => {
          var b64 = reader.result;
          console.log(`B64 = ${b64}`);
          console.log(file);
          setFileB64Str2(b64);
          setFileName2(file.name);
        };
        reader.onerror = (err) => {
          console.log(`Error: ${err}`);
        };
      }
    }
  };

  const encodeFile3B64 = (file, link) => {
    var reader = new FileReader();
    if (file) {
      if (file.size < 1000000) {
        reader.readAsDataURL(file);
        reader.onload = () => {
          var b64 = reader.result;
          console.log(`B64 = ${b64}`);
          console.log(file);
          setFileB64Str3(b64);
          setFileName3(file.name);
        };
        reader.onerror = (err) => {
          console.log(`Error: ${err}`);
        };
      }
    }
  };

  const encodeFile4B64 = (file, link) => {
    var reader = new FileReader();
    if (file) {
      if (file.size < 1000000) {
        reader.readAsDataURL(file);
        reader.onload = () => {
          var b64 = reader.result;
          console.log(`B64 = ${b64}`);
          console.log(file);
          setFileB64Str4(b64);
          setFileName4(file.name);
        };
        reader.onerror = (err) => {
          console.log(`Error: ${err}`);
        };
      }
    }
  };
  const encodeFile5B64 = (file, link) => {
    var reader = new FileReader();
    if (file) {
      if (file.size < 1000000) {
        reader.readAsDataURL(file);
        reader.onload = () => {
          var b64 = reader.result;
          console.log(`B64 = ${b64}`);
          console.log(file);
          setFileB64Str5(b64);
          setFileName5(file.name);
        };
        reader.onerror = (err) => {
          console.log(`Error: ${err}`);
        };
      }
    }
  };
  const encodeFile6B64 = (file, link) => {
    var reader = new FileReader();
    if (file) {
      if (file.size < 1000000) {
        reader.readAsDataURL(file);
        reader.onload = () => {
          var b64 = reader.result;
          console.log(`B64 = ${b64}`);
          console.log(file);
          setFileB64Str6(b64);
          setFileName6(file.name);
        };
        reader.onerror = (err) => {
          console.log(`Error: ${err}`);
        };
      }
    }
  };
  const encodeFile7B64 = (file, link) => {
    var reader = new FileReader();
    if (file) {
      if (file.size < 1000000) {
        reader.readAsDataURL(file);
        reader.onload = () => {
          var b64 = reader.result;
          console.log(`B64 = ${b64}`);
          console.log(file);
          setFileB64Str7(b64);
          setFileName7(file.name);
        };
        reader.onerror = (err) => {
          console.log(`Error: ${err}`);
        };
      }
    }
  };

  useEffect(() => {
    sessionStorage.setItem('createCustomerPayer', JSON.stringify(fields));
  }, [fields]);

  useEffect(() => {
    console.log('lalal');
    if (fileB64Str !== '' && fileName !== '') {
      setValues({
        ...fields,
        ['supportDocsBank']: fileB64Str,
        ['supportDocsBankName']: fileName,
      });
    }
  }, [fileB64Str, fileName]);

  useEffect(() => {
    console.log('lalal');
    if (fileB64Str1 !== '' && fileName1 !== '') {
      setValues({
        ...fields,
        ['supportDocsBank1']: fileB64Str1,
        ['supportDocsBankName1']: fileName1,
      });
    }
  }, [fileB64Str1, fileName1]);

  useEffect(() => {
    console.log('lalal');
    if (fileB64Str2 !== '' && fileName2 !== '') {
      setValues({
        ...fields,
        ['supportDocsBank2']: fileB64Str2,
        ['supportDocsBankName2']: fileName2,
      });
    }
  }, [fileB64Str2, fileName2]);

  useEffect(() => {
    console.log('lalal3');
    if (fileB64Str3 !== '' && fileName3 !== '') {
      setValues({
        ...fields,
        ['supportDocsBank3']: fileB64Str3,
        ['supportDocsBankName3']: fileName3,
      });
    }
  }, [fileB64Str3, fileName3]);

  useEffect(() => {
    console.log('lalal3');
    if (fileB64Str5 !== '' && fileName5 !== '') {
      setValues({
        ...fields,
        ['supportDocsBank4']: fileB64Str5,
        ['supportDocsBankName4']: fileName5,
      });
    }
  }, [fileB64Str5, fileName5]);

  useEffect(() => {
    console.log('lalal3');
    if (fileB64Str6 !== '' && fileName6 !== '') {
      setValues({
        ...fields,
        ['supportDocsBank5']: fileB64Str6,
        ['supportDocsBankName5']: fileName6,
      });
    }
  }, [fileB64Str6, fileName6]);

  useEffect(() => {
    console.log('lalal3');
    if (fileB64Str7 !== '' && fileName7 !== '') {
      setValues({
        ...fields,
        ['supportDocsBank6']: fileB64Str7,
        ['supportDocsBankName6']: fileName7,
      });
    }
  }, [fileB64Str7, fileName7]);

  useEffect(() => {
    console.log('lalal3');
    if (fileB64Str4 !== '' && fileName4 !== '') {
      setValues({
        ...fields,
        ['supportNoVAT']: fileB64Str4,
        ['supportNoVATName']: fileName4,
      });
    }
  }, [fileB64Str4, fileName4]);

  const onFileChange = (e) => {
    //   setSelectedFile(e.target.files);
    encodeFileB64(e.target.files[0]);
    console.log(e.target.files[0]);
    //   setInputs(e);
  };

  return [
    fields,
    function (event, action) {
      // console.log(event)
      if (event == 'creditBlock') {
        setValues({
          ...fields,
          creditBlock: action,
        });
      } else if (action?.name == 'paymentTerms') {
        console.log(action);
        if (action.action == 'clear') {
          setValues({
            ...fields,
            paymentTerms: '',
            paymentTermsDesc: '',
          });
        } else {
          console.log(event);
          setValues({
            ...fields,
            paymentTerms: event.termsOfPayment,
            paymentTermsDesc: event.paymentVal,
          });
        }
      } else if (action?.name == 'paymentMethod') {
        console.log(action);
        if (action.action == 'clear') {
          setValues({
            ...fields,
            paymentMethod: '',
          });
        } else {
          console.log(event);
          setValues({
            ...fields,
            paymentMethod: event.paymentMethod,
          });
        }
      } else if (action?.name == 'reasonDirectDebit') {
        console.log(action);
        if (action.action == 'clear') {
          setValues({
            ...fields,
            reasonDirectDebit: '',
          });
        } else {
          console.log(event);
          setValues({
            ...fields,
            reasonDirectDebit: event.reasonDirectDebit,
          });
        }
      } else if (action?.name == 'region') {
        console.log(action);
        if (action.action == 'clear') {
          setValues({
            ...fields,
            region: '',
            regionDesc: '',
          });
        } else {
          console.log(event);
          setValues({
            ...fields,
            region: event.regionCode,
            regionDesc: event.regionDesc,
          });
        }
      } else if (action?.name == 'language') {
        console.log(action);
        if (action.action == 'clear') {
          setValues({
            ...fields,
            language: '',
            langDesc: '',
            // regionDesc: '',
            // erpTranspZone: '',
          });
        } else {
          console.log(event);
          setValues({
            ...fields,
            language: event.langText,
            langDesc: event.langDesc,
            // erpTranspZone: event.erpTranspZone,
          });
        }
      } else if (event.target.name === 'supportDocsBank') {
        encodeFileB64(event.target.files[0], event.target.value);
        // console.log(`Res: ${fileB64Str}`);
      } else if (event.target.id === 'clearFile') {
        console.log('sup');
        setValues({
          ...fields,
          ['supportDocsBank']: '',
          ['supportDocsBankName']: '',
        });
      } else if (event.target.name === 'supportDocsBank1') {
        encodeFile1B64(event.target.files[0], event.target.value);
        console.log(`Res: ${fileB64Str}`);
      } else if (event.target.id === 'clearFile1') {
        console.log('sup1');
        setValues({
          ...fields,
          ['supportDocsBank1']: '',
          ['supportDocsBankName1']: '',
        });
      } else if (event.target.name === 'supportDocsBank2') {
        encodeFile2B64(event.target.files[0], event.target.value);
        console.log(`Res: ${fileB64Str}`);
      } else if (event.target.id === 'clearFile2') {
        console.log('sup2');
        setValues({
          ...fields,
          ['supportDocsBank2']: '',
          ['supportDocsBankName2']: '',
        });
      } else if (event.target.name === 'supportDocsBank3') {
        encodeFile3B64(event.target.files[0], event.target.value);
        console.log(`Res: ${fileB64Str}`);
      } else if (event.target.id === 'clearFile3') {
        console.log('sup2');
        setValues({
          ...fields,
          ['supportDocsBank3']: '',
          ['supportDocsBankName3']: '',
        });
      } else if (event.target.name === 'supportDocsBank4') {
        encodeFile5B64(event.target.files[0], event.target.value);
        // console.log(`Res: ${fileB64Str}`);
      } else if (event.target.id === 'clearFile4') {
        console.log('sup');
        setValues({
          ...fields,
          ['supportDocsBank']: '',
          ['supportDocsBankName']: '',
        });
      } else if (event.target.name === 'supportDocsBank5') {
        encodeFile6B64(event.target.files[0], event.target.value);
        // console.log(`Res: ${fileB64Str}`);
      } else if (event.target.id === 'clearFile5') {
        console.log('sup');
        setValues({
          ...fields,
          ['supportDocsBank']: '',
          ['supportDocsBankName']: '',
        });
      } else if (event.target.name === 'supportDocsBank6') {
        encodeFile7B64(event.target.files[0], event.target.value);
        // console.log(`Res: ${fileB64Str}`);
      } else if (event.target.id === 'clearFile6') {
        console.log('sup');
        setValues({
          ...fields,
          ['supportDocsBank']: '',
          ['supportDocsBankName']: '',
        });
      } else if (event.target.name === 'supportNoVAT') {
        encodeFile4B64(event.target.files[0], event.target.value);
        console.log(`Res: ${fileB64Str}`);
      } else if (event.target.id === 'clearFileNoVat') {
        console.log('sup2');
        setValues({
          ...fields,
          ['supportNoVAT']: '',
          ['supportNoVATName']: '',
        });
      } else if (event.target.name === 'title') {
        setValues({
          ...fields,
          [event.target.name]: event.target.value,
          titleDesc: event.target.selectedOptions[0].getAttribute('data-title'),
        });
      } else if (
        !['France', 'Netherlands'].includes(country) &&
        event.target.name === 'vatRegNumber'
      ) {
        setValues({
          ...fields,
          [event.target.name]: event.target.value.toUpperCase(),
          taxNumber: event.target.value.slice(2),
        });
      } else if (event.target.name == 'vatExist') {
        if (event.target.value == 'No') {
          setValues({
            ...fields,
            [event.target.name]: event.target.value,
            vatRegNumber: ['Netherlands'].includes(country) ? 'NO VAT' : '0111111111',
          });
        } else if (event.target.value == 'In Progress') {
          setValues({
            ...fields,
            [event.target.name]: event.target.value,
            vatRegNumber: '0999999999',
          });
        } else {
          setValues({
            ...fields,
            [event.target.name]: event.target.value,
            vatRegNumber: '',
          });
        }
      } else if (
        [
          'ediOption',
          'ediType',
          'eInvOption',
          'eInvEmailType',
          'isVendor',
          'payerCountry',
          'title',
          'onInvoicingDiscount',
          'isVAT',
          // 'rent',
        ].includes(event.target.name)
      ) {
        setValues({
          ...fields,
          [event.target.name]: event.target.value,
        });
      } else if (['rent'].includes(event.target.name)) {
        if (event.target.value == 'Yes') {
          console.log(event);
          setValues({
            ...fields,
            rent: event.target.value,
            paymentMethod: 'Direct Debit Beer and Rent',
          });
        } else {
          setValues({
            ...fields,
            [event.target.name]: event.target.value,
          });
        }
      } else {
        console.log(event.target.id, event.target.name);
        setValues({
          ...fields,
          [event.target.name]: event.target.value.toUpperCase(),
        });
      }
    },
  ];
}

// else if (event.target.name === 'region') {
//   setValues({
//     ...fields,
//     [event.target.name]: event.target.value,
//     regionDesc: event.target.selectedOptions[0].getAttribute('data-region'),
//   });
// }

/* eslint-disable no-useless-catch */
import { useState, useEffect } from 'react';
import axios from './axios';
// import axios from 'axios';

import { urls } from './Utils';

export default {
  getAllRequestsPaginated: async function (pageNum = 1, type) {
    try {
      const response = await axios.get(urls.fetchAllRequestsURL, {
        params: {
          // requestor: requestor,
          //requestor: 'PIERRE-ANTOINE.DUVERNEY@INTERBREW.NET',
          page: pageNum,
          type: type,
          // query: query,
        },
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getDraftRequestsPaginated: async function (requestor, pageNum = 1, type) {
    try {
      const response = await axios.get(urls.fetchDraftRequestsURL, {
        params: {
          requestor: requestor,
          //requestor: 'PIERRE-ANTOINE.DUVERNEY@INTERBREW.NET',
          page: pageNum,
          type: type,
          // query: query,
        },
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getSubmittedRequestsPaginated: async function (requestor, pageNum = 1, type = 'Customer') {
    try {
      const response = await axios.get(urls.fetchSubmittedRequestsURL, {
        params: {
          requestor: requestor,
          page: pageNum,
          type: type,
        },
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getRejectedRequestsPaginated: async function (requestor, pageNum = 1, type = 'Customer') {
    try {
      const response = await axios.get(urls.fetchRejectedRequestsURL, {
        params: {
          requestor: requestor,
          page: pageNum,
          type: type,
        },
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getDuplicateRequestsPaginated: async function (requestor, pageNum = 1, type = 'Customer') {
    try {
      const response = await axios.get(urls.fetchDuplicateRequestsURL, {
        params: {
          requestor: requestor,
          page: pageNum,
          type: type,
        },
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getCompletedRequestsPaginated: async function (requestor, pageNum = 1, type = 'Customer') {
    try {
      const response = await axios.get(urls.fetchCompletedRequestsURL, {
        params: {
          requestor: requestor,
          page: pageNum,
          type: type,
        },
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getAllFilteredRequestsPaginated: async function (
    pageNum = 1,
    searchTerm,
    startDate,
    endDate,
    requestTypeSelected,
    countrySelected,
    statusSelected,
    type = 'Customer',
  ) {
    try {
      // const response = await axios.get(urls.fetchAllFilteredRequestsURL, {
      //   params: {
      //     // requestor: requestor,
      //     page: pageNum,
      //     searchTerm: searchTerm,
      //     type: type,
      //     startDate: startDate,
      //     endDate: endDate,
      //     requestTypeSelected: requestTypeSelected,
      //     countrySelected: countrySelected,
      //   },
      // });
      const response = await axios.post(urls.fetchAllFilteredRequestsURL, {
        page: pageNum,
        searchTerm: searchTerm,
        type: type,
        startDate: startDate,
        endDate: endDate,
        requestTypeSelected: requestTypeSelected,
        countrySelected: countrySelected,
        statusSelected: statusSelected,
      });
      // .then((res) => {
      //   console.log(res);
      //   axios.get(urls.fetchAllFilteredRequestsURL, {
      //     params: {
      //       page: pageNum,
      //     },
      //   });
      // });

      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getAllFilteredExports: async function (
    searchTerm,
    startDate,
    endDate,
    requestTypeSelected,
    countrySelected,
    statusSelected,
    type = 'Customer',
  ) {
    try {
      // const response = await axios.get(urls.fetchAllFilteredRequestsURL, {
      //   params: {
      //     // requestor: requestor,
      //     page: pageNum,
      //     searchTerm: searchTerm,
      //     type: type,
      //     startDate: startDate,
      //     endDate: endDate,
      //     requestTypeSelected: requestTypeSelected,
      //     countrySelected: countrySelected,
      //   },
      // });
      const response = await axios.post(urls.fetchAllFilteredExportsURL, {
        // page: pageNum,
        searchTerm: searchTerm,
        type: type,
        startDate: startDate,
        endDate: endDate,
        requestTypeSelected: requestTypeSelected,
        countrySelected: countrySelected,
        statusSelected: statusSelected,
      });
      // .then((res) => {
      //   console.log(res);
      //   axios.get(urls.fetchAllFilteredRequestsURL, {
      //     params: {
      //       page: pageNum,
      //     },
      //   });
      // });

      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // getAllExports: async function (
  //   // searchTerm,
  //   // startDate,
  //   // endDate,
  //   // requestTypeSelected,
  //   // countrySelected,
  //   type = 'Customer',
  // ) {
  //   try {
  //     const response = await axios.get(urls.fetchExportsURL, {
  //       // searchTerm: searchTerm,
  //       type: type,
  //       // startDate: startDate,
  //       // endDate: endDate,
  //       // requestTypeSelected: requestTypeSelected,
  //       // countrySelected: countrySelected,
  //     });

  //     return response.data;
  //   } catch (error) {
  //     throw error;
  //   }
  // },

  getSubmittedFilteredRequestsPaginated: async function (
    requestor,
    pageNum = 1,
    submittedSearchTerm,
    type = 'Customer',
  ) {
    try {
      const response = await axios.get(urls.fetchSubmittedFilteredRequestsURL, {
        params: {
          requestor: requestor,
          page: pageNum,
          submittedSearchTerm: submittedSearchTerm,
          type: type,
        },
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getDraftFilteredRequestsPaginated: async function (
    requestor,
    pageNum = 1,
    draftSearchTerm,
    type = 'Customer',
  ) {
    try {
      const response = await axios.get(urls.fetchDraftFilteredRequestsURL, {
        params: {
          requestor: requestor,
          page: pageNum,
          draftSearchTerm: draftSearchTerm,
          type: type,
        },
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getDuplicateFilteredRequestsPaginated: async function (
    requestor,
    pageNum = 1,
    duplicateSearchTerm,
    type = 'Customer',
  ) {
    try {
      const response = await axios.get(urls.fetchDuplicateFilteredRequestsURL, {
        params: {
          requestor: requestor,
          page: pageNum,
          duplicateSearchTerm: duplicateSearchTerm,
          type: type,
        },
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getRejectedFilteredRequestsPaginated: async function (
    requestor,
    pageNum = 1,
    rejectedSearchTerm,
    type = 'Customer',
  ) {
    try {
      const response = await axios.get(urls.fetchRejectedFilteredRequestsURL, {
        params: {
          requestor: requestor,
          page: pageNum,
          rejectedSearchTerm: rejectedSearchTerm,
          type: type,
        },
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getCompletedFilteredRequestsPaginated: async function (
    requestor,
    pageNum = 1,
    completedSearchTerm,
    type = 'Customer',
  ) {
    try {
      const response = await axios.get(urls.fetchCompletedFilteredRequestsURL, {
        params: {
          requestor: requestor,
          page: pageNum,
          completedSearchTerm: completedSearchTerm,
          type: type,
        },
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  },

  deleteDraftRequest: async function (ticket, requestor) {
    try {
      const response = await axios.delete(urls.deleteDraftURL, {
        params: {
          ticketNum: ticket,
          requestor: requestor,
          // type: type,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getApprovalButton: async function (requestor, title, reqType) {
    try {
      const response = await axios.get(urls.getApprovalButtonURL, {
        params: {
          requestor: requestor,
          title: title,
          reqType: reqType,
        },
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  },

  updateVendorRequest: async function (requestor, ticket, vendorType, vendorNum, vendorIssues) {
    try {
      const response = await axios.post(urls.updateVendorDataURL, {
        params: {
          requestor: requestor,
          ticketNum: ticket,
          vendorType: vendorType,
          vendorNum: vendorNum,
          vendorIssue: vendorIssues,
          // type: type,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getListingFeesPendingPaginated: async function (pageNum = 1, type) {
    try {
      const response = await axios.get(urls.fetchListingFeesPendingRequestsURL, {
        params: {
          // requestor: requestor,
          //requestor: 'PIERRE-ANTOINE.DUVERNEY@INTERBREW.NET',
          page: pageNum,
          type: type,
          // query: query,
        },
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getPendingListingFeesFilteredRequestsPaginated: async function (
    requestor,
    pageNum = 1,
    completedSearchTerm,
    type = 'Customer',
  ) {
    try {
      const response = await axios.get(urls.fetchListingFeeFilteredRequestsURL, {
        params: {
          requestor: requestor,
          page: pageNum,
          completedSearchTerm: completedSearchTerm,
          type: type,
        },
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getListingFeesCompletedPaginated: async function (requestor, pageNum = 1, type) {
    try {
      const response = await axios.get(urls.fetchListingFeesCompletedRequestsURL, {
        params: {
          requestor: requestor,
          //requestor: 'PIERRE-ANTOINE.DUVERNEY@INTERBREW.NET',
          page: pageNum,
          type: type,
          // query: query,
        },
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getCompletedListingFeesFilteredRequestsPaginated: async function (
    requestor,
    pageNum = 1,
    completedSearchTerm,
    type = 'Customer',
  ) {
    try {
      const response = await axios.get(urls.fetchListingFeeCompletedFilteredRequestsURL, {
        params: {
          requestor: requestor,
          page: pageNum,
          completedSearchTerm: completedSearchTerm,
          type: type,
        },
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

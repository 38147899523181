import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';

import { Row, Col, ButtonGroup, Button, Modal, Spinner } from 'react-bootstrap';
import Select, { components } from 'react-select';
import FeatherIcon from 'feather-icons-react';
import MandatoryText from '../../../components/MandatoryText';
import { Switch } from '@mantine/core';

import { FetchRegions, FetchLanguages } from '../shipto/FlowCallAPI';
import {
  FetchTitles,
  FetchPaymentTerms,
  FetchPayerBapi,
  FetchPaymentMethod,
  FetchReasonDirectDebit,
} from './FlowCallAPI';
import { FetchCountries } from '../mainpage/FlowCallAPI';
import { languages, einvoicingOptions, languageMap, vatPlaceholder } from '../../../utils/Utils';
import { formTemplate } from '../Data';
import { useFormFields } from './HooksLib';
import { validateFields } from './FormValidation';
import Sidebar from '../../../components/layout/Sidebar/Sidebar';
import LangSelect from '../../../components/core/InputFields/LanguageSelect/LanguageSelect';
import CustomTextInput from '../../../components/core/InputFields/CustomTextInput/CustomTextInput';
import CustomTelInput from '../../../components/core/InputFields/CustomTelInput/CustomTelInput';
import RegionSelect from '../../../components/core/InputFields/RegionSelect/RegionSelect';
import {
  mobileLength,
  mobilePlaceholder,
  telephoneLength,
  telephonePlaceholder,
  ibanLength,
  ibanPlaceholder,
  vatLength,
  postalCodeLength,
  payerIDRegex,
} from '../../../utils/Utils';

const CreateCustPayer = (props) => {
  const navigate = useNavigate();

  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (!isAuthenticated) navigate('/');
  }, [isAuthenticated]);

  const { instance, accounts } = useMsal();

  const [isDisable, setIsDisable] = useState(false);

  const fileRegex = /\.[^.\\/:*?"<>|\r\n]+$/;

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isCopying, setIsCopying] = useState(false);
  const [copyClick, setCopyClick] = useState(false);
  const [isDisableFields, setIsDisableFields] = useState(false);
  const [linkForward, setLinkForward] = useState('');
  // const [payerForm, setPayerForm] = useState(sessionStorage.getItem('createCustomerPayer') === null ? payerFormTemplate : JSON.parse(sessionStorage.getItem('createCustomerPayer')));
  // const [inputs, setInputs] = useFormFields(payerForm);
  const [inputs, setInputs] = useFormFields(props.formTemplate);
  const country = JSON.parse(sessionStorage.getItem('createCustomerMainPage')).country || '';
  const companyCode =
    JSON.parse(sessionStorage.getItem('createCustomerMainPage')).companyCode || '';
  const salesOrg = JSON.parse(sessionStorage.getItem('createCustomerMainPage')).salesOrg || '';
  const shipToType = JSON.parse(sessionStorage.getItem('createCustomerMainPage')).shipToType || '';
  const customerReqType =
    JSON.parse(sessionStorage.getItem('createCustomerMainPage')).customerReqType || '';
  const mainPageData = JSON.parse(sessionStorage.getItem('createCustomerMainPage'));
  const shipToData = JSON.parse(sessionStorage.getItem('createCustomerShipTo'));
  const titlesRes = FetchTitles(country, isDisableFields);
  const regionsRes = FetchRegions(inputs.payerCountry, isDisableFields);
  const paymentTermsRes = FetchPaymentTerms(country, isDisableFields, shipToType);
  const paymentMethodRes = FetchPaymentMethod(country);
  const reasonDirectDebitRes = FetchReasonDirectDebit(country);
  const payerDataRes = FetchPayerBapi(inputs.existingPayerID, companyCode, salesOrg, isCopying);
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [showModal3, setShowModal3] = useState(false);
  const [showModal4, setShowModal4] = useState(false);
  const [showModal5, setShowModal5] = useState(false);
  const [showModal6, setShowModal6] = useState(false);
  const [showNotVatModal, setShowNotVatModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleClose1 = () => setShowModal1(false);
  const handleClose2 = () => setShowModal2(false);
  const handleClose3 = () => setShowModal3(false);
  const handleClose4 = () => setShowModal4(false);
  const handleClose5 = () => setShowModal5(false);
  const handleClose6 = () => setShowModal6(false);
  const handleCloseNoVAT = () => setShowNotVatModal(false);
  const [fileName, setFileName] = useState('');
  const [file, setFile] = useState('');
  const countriesRes = FetchCountries();
  const languagesRes = FetchLanguages(isDisableFields);
  const languageOptions = languagesRes.languages.map((val) => {
    return {
      ...val,
      langCode: val.langCode,
      langText: val.langText,
      langDesc: `${val.langCode} - ${val.langText}`,
    };
  });
  function xyz(e) {
    e.preventDefault();
  }

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    if (
      props.draftTicketNum[0] !== 'D' &&
      props.status !== 'Duplicate' &&
      props.status !== 'Rejected' &&
      props.status !== 'Duplicate'
    ) {
      //if ((props.draftTicketNum[0] === 'D')) {
      setIsDisable(true);
    }

    if (inputs.isVendor == '') inputs.isVendor = 'No';
    if (inputs.telephone == '') inputs.telephone = shipToData.generalTelephone;
    if (inputs.mobile == '') inputs.mobile = shipToData.mobile;
    if (inputs.email == '') inputs.email = shipToData.email;
    if (inputs.payerCountry == '') inputs.payerCountry = country;
    inputs.eInvEmailType = 'General/Accounting';

    switch (country) {
      case 'Belgium':
        if (inputs.vatRegNumber == '0111111111') inputs.vatExist = 'No';
        else if (inputs.vatRegNumber == '0999999999') inputs.vatExist = 'In Progress';
        else inputs.vatExist = 'Yes';

        if (shipToType.endsWith('Direct') && inputs.paymentTerms == '')
          inputs.paymentTerms = '014I';
        else if (shipToType.endsWith('Indirect') && inputs.paymentTerms == '')
          inputs.paymentTerms = '014I';
        else if (!shipToType.startsWith('Wholesaler') && inputs.paymentTerms == '')
          inputs.paymentTerms = '014I';

        if (inputs.language == '') inputs.language = 'English';

        if (shipToType == 'Off-Trade - Indirect' && inputs.eInvOption == '')
          inputs.eInvOption = 'No';

        // if (shipToType.includes('Wholesaler') && inputs.onInvoicingDiscount == '')
        //   inputs.onInvoicingDiscount = 'Yes';

        if (shipToType.startsWith('On-Trade') && inputs.rent == '') inputs.rent = 'No';
        break;
      case 'Netherlands':
        if (inputs.vatRegNumber == 'NO VAT') inputs.vatExist = 'No';
        else if (inputs.vatRegNumber == '0999999999') inputs.vatExist = 'In Progress';
        else inputs.vatExist = 'Yes';
        if (inputs.language == '') inputs.language = 'Dutch';
        if (shipToType.endsWith('Indirect') && inputs.paymentTerms == '')
          inputs.paymentTerms = '000A';

        if (shipToType.startsWith('On-Trade') && inputs.rent == '') inputs.rent = 'No';
        break;

      case 'United Kingdom':
        if (inputs.language == '') inputs.language = 'English';
        if (shipToType.endsWith('Indirect') && inputs.paymentTerms == '')
          inputs.paymentTerms = '0000';
        break;

      case 'France':
        if (inputs.vatRegNumber == '0111111111') inputs.vatExist = 'No';
        else if (inputs.vatRegNumber == '0999999999') inputs.vatExist = 'In Progress';
        else inputs.vatExist = 'Yes';

        if (inputs.language == '') inputs.language = 'French';

        if (inputs.paymentTerms == '') {
          if (shipToType == 'Wholesale– Direct') inputs.paymentTerms = '030M';
          else if (shipToType == 'Wholesale– Indirect') inputs.paymentTerms = '030I';
          else if (shipToType.startsWith('On-Trade')) inputs.paymentTerms = '030M';
          else inputs.paymentTerms = '045M';
        }

        // inputs.paymentTerms = shipToType.startsWith('On-Trade') ? '030M' : '045M';
        break;

      case 'Germany':
        if (inputs.vatRegNumber == '0111111111') inputs.vatExist = 'No';
        else if (inputs.vatRegNumber == '0999999999') inputs.vatExist = 'In Progress';
        else inputs.vatExist = 'Yes';
        if (inputs.language == '') inputs.language = 'German';
        if (inputs.paymentTerms == '') inputs.paymentTerms = '014I';
        break;

      case 'Finland':
        if (inputs.vatRegNumber == '0111111111') inputs.vatExist = 'No';
        else if (inputs.vatRegNumber == '0999999999') inputs.vatExist = 'In Progress';
        else inputs.vatExist = 'Yes';

        if (inputs.language == '') inputs.language = 'Finnish';
        if (inputs.paymentTerms == '') inputs.paymentTerms = '014I';
        break;

      case 'Austria':
        if (inputs.vatRegNumber == '0111111111') inputs.vatExist = 'No';
        else if (inputs.vatRegNumber == '0999999999') inputs.vatExist = 'In Progress';
        else inputs.vatExist = 'Yes';
        if (inputs.language == '') inputs.language = 'German';
        break;

      case 'Denmark':
        if (inputs.vatRegNumber == '0111111111') inputs.vatExist = 'No';
        else if (inputs.vatRegNumber == '0999999999') inputs.vatExist = 'In Progress';
        else inputs.vatExist = 'Yes';

        if (inputs.language == '') inputs.language = 'Danish';
        break;

      case 'Norway':
        if (inputs.vatRegNumber == '0111111111') inputs.vatExist = 'No';
        else if (inputs.vatRegNumber == '0999999999') inputs.vatExist = 'In Progress';
        else inputs.vatExist = 'Yes';

        inputs.eInvOption = 'Yes';
        if (inputs.language == '') inputs.language = 'Norwegian';
        break;

      case 'Poland':
        if (inputs.language == '') inputs.language = 'Polish';
        break;

      case 'Sweden':
        if (inputs.vatRegNumber == '0111111111') inputs.vatExist = 'No';
        else if (inputs.vatRegNumber == '0999999999') inputs.vatExist = 'In Progress';
        else inputs.vatExist = 'Yes';

        inputs.eInvOption = 'Yes';
        if (inputs.language == '') inputs.language = 'Swedish';
        break;

      case 'Switzerland':
        if (inputs.vatRegNumber == '0111111111') inputs.vatExist = 'No';
        else if (inputs.vatRegNumber == '0999999999') inputs.vatExist = 'In Progress';
        else inputs.vatExist = 'Yes';
        if (inputs.language == '') inputs.language = 'French';
        break;

      case 'Italy':
        if (inputs.language == '') inputs.language = 'Italian';
        inputs.eInvOption = 'Yes';
        inputs.eInvEmailType = 'Invoicing';
        break;

      case 'Luxembourg':
        if (inputs.language == '') inputs.language = 'French';
        break;

      default:
        break;
    }
  }, []);

  useEffect(() => {
    setFileName(inputs.supportDocsBankName);
    setFile(inputs.supportDocsBank);
  }, [inputs.supportDocsBank, inputs.supportDocsBankName]);

  const FileSize = (event) => {
    if (event.target.files[0].size > 1000000) {
      console.log('Hello please reduce', event.target.files[0].size);
      setShowErrorModal(true);
    }
  };

  function getLang(id) {
    // console.log(props.translations);
    let obj = props.translations.find((o) => o.textID === id);
    // console.log(obj);
    if (!obj) {
      return id;
    } else {
      return obj.localizedText;
    }
  }
  const handleCreditBlock = (event) => {
    const { name, checked } = event.target;
    // Do something with the name and checked values
    console.log(`Switch ${name} is ${checked ? 'checked' : 'unchecked'}`);
    setInputs(name, checked);
  };
  const handleSubmit = (e) => {
    console.log('Submitting...');
    if (e) e.preventDefault();
    if (e.target.getAttribute('data-back') === 'true')
      props.setActivePage(e.target.getAttribute('data-link'));
    else {
      // if (!isDisableFields) {
      setLinkForward(e.target.getAttribute('data-link'));
      setErrors(
        validateFields(
          inputs,
          country,
          mainPageData.shipToType,
          mainPageData.customerReqType,
          inputs.title,
        ),
      );
      // }
      setIsSubmitting(true);
      // if (isDisableFields || isDisable) props.setActivePage(e.target.getAttribute('data-link'));
      if (isDisable) props.setActivePage(e.target.getAttribute('data-link'));
    }
  };

  useEffect(() => {
    console.log(`${JSON.stringify(errors)} ${isSubmitting}`);
    if (Object.keys(errors).length === 0 && isSubmitting) {
      console.log('Can go forward');
      props.setActivePage(linkForward);
    } else if (Object.keys(errors).length === 0 && copyClick) {
      setCopyClick(false);
      setIsCopying(true);
      Object.entries(formTemplate.payer).forEach(([key, value]) => {
        if (
          !['existingPayerID', 'language', 'paymentTerms', 'isVendor', 'payerCountry'].includes(key)
        )
          inputs[key] = value;
      });
    } else {
      setIsSubmitting(false);
      setCopyClick(false);
      setIsCopying(false);
    }
  }, [errors]);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);

  useEffect(() => {
    window.addEventListener(
      'resize',
      () => {
        const ismobile = window.innerWidth < 992;
        if (ismobile !== isMobile) setIsMobile(ismobile);
      },
      false,
    );
  }, [isMobile]);

  const [isSidebarOpen, setisSidebarOpen] = useState(false);

  const togglingSidebar = () => {
    setisSidebarOpen(!isSidebarOpen);
  };

  const [showEInvSection, setShowEInvSection] = useState(false);

  function eInvOptionChange(e) {
    if (e.target.value === 'Yes') setShowEInvSection(true);
    else setShowEInvSection(false);
    setInputs(e);
  }

  function copyPayerData(e) {
    if (!isDisable && payerIDRegex.test(inputs.existingPayerID)) {
      setCopyClick(true);
      setIsCopying(true);
    }
    // setIsCopying(true);
    // setCopyClick(true);
    // setErrors(validateFields(inputs, country, mainPageData.shipToType, mainPageData.customerReqType, inputs.title));
    // console.log(`I am true ${isCopying}`);
    // console.log(isCopying, inputs.existingPayerID, companyCode, salesOrg);
  }

  useEffect(() => {
    if (Object.keys(payerDataRes.payerData).length !== 0 && isCopying) {
      Object.entries(payerDataRes.payerData).forEach(([key, value]) => {
        if (key == 'contactName') inputs.contactPerson = value;
        else if (key in inputs) {
          if (key === 'telephone' && value !== '') {
            inputs.telephoneOption = 'General/Accounting';
            inputs[key] = value;
          } else if (key == 'vatRegNumber' && value != '') {
            inputs.vatExist = 'Yes';
            inputs[key] = value;
          } else if (key == 'language') inputs.language = languageMap[value];
          else inputs[key] = value;
        }
      });
      sessionStorage.setItem('createCustomerPayer', JSON.stringify(inputs));
    }
    setIsCopying(false);
  }, [payerDataRes.payerData]);

  useEffect(() => {
    if (inputs.existingPayerID !== '') {
      setErrors({});
      setIsDisableFields(true);
    } else setIsDisableFields(false);
  }, [inputs.existingPayerID]);

  useEffect(() => {
    if (country == 'Belgium' && inputs.eInvOption == '') inputs.eInvOption = 'Yes';
    setShowEInvSection(true);
  }, [inputs]);

  function clearFile() {
    inputs.supportDocsBank = '';
    inputs.supportDocsBankName = '';
    setFile('');
    setFileName('');
    sessionStorage.setItem('createCustomerPayer', JSON.stringify(inputs));
  }

  const Input = (props) => <components.Input {...props} maxLength={8} />;

  const paymentTermsOptions = paymentTermsRes.paymentTerms.map((val) => {
    if (val.termsOfPayment == '0000')
      return {
        ...val,
        termsOfPayment: val.termsOfPayment,
        paymentDesc: val.paymentDesc,
        paymentVal: val.termsOfPayment,
      };
    else
      return {
        ...val,
        termsOfPayment: val.termsOfPayment,
        paymentDesc: val.paymentDesc,
        paymentVal: `${val.termsOfPayment} - ${val.paymentDesc}`,
      };
  });

  const paymentMethodOptions = paymentMethodRes.paymentMethod.map((val) => {
    return {
      ...val,
      paymentMethod: val.paymentMethod,
    };
  });

  const reasonDirectDebitOptions = reasonDirectDebitRes.reasonDirectDebit.map((val) => {
    return {
      ...val,
      reasonDirectDebit: val.reasonDirectDebit,
    };
  });

  const regionOptions = regionsRes.regions.map((val) => {
    return {
      ...val,
      regionCode: val.regionCode,
      regionText: val.regionText,
      erpTranspZone: val.erpTranspZone,
      regionDesc: `${val.regionCode} - ${val.regionText}`,
    };
  });

  const sidebarItems = [
    {
      title: getLang('Main Page'),
      onClick: handleSubmit,
      ['data-link']: 'mainpage',
      ['data-back']: 'true',
      active: false,
    },
    {
      title: getLang('Ship-To'),
      onClick: handleSubmit,
      ['data-link']: 'shipto',
      ['data-back']: true,
      // type: 'submit',
      active: false,
    },
    {
      title: getLang('Payer'),
      onClick: handleSubmit,
      ['data-link']: 'payer',
      ['data-back']: 'true',
      // type: 'submit',
      active: true,
    },
    {
      title: getLang('Sold-To'),
      onClick: handleSubmit,
      ['data-link']: 'soldto',
      // ['data-back']: 'true',
      // type: 'submit',
      active: false,
    },
    {
      title: getLang('Bill-To'),
      onClick: handleSubmit,
      ['data-link']: 'billto',
      ['data-back']: 'true',
      // type: 'submit',
      active: false,
    },
  ];

  return (
    isAuthenticated && (
      <form className="card" onSubmit={handleSubmit}>
        <Modal show={showModal} onHide={handleClose}>
          <Modal.Header>
            <Modal.Title>Preview</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img src={inputs.supportDocsBank}></img>
          </Modal.Body>
        </Modal>

        <Modal show={showModal1} onHide={handleClose1}>
          <Modal.Header>
            <Modal.Title>Preview</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img src={inputs.supportDocsBank1}></img>
          </Modal.Body>
        </Modal>

        <Modal show={showModal2} onHide={handleClose2}>
          <Modal.Header>
            <Modal.Title>Preview</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img src={inputs.supportDocsBank2}></img>
          </Modal.Body>
        </Modal>

        <Modal show={showModal3} onHide={handleClose3}>
          <Modal.Header>
            <Modal.Title>Preview</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img src={inputs.supportDocsBank3}></img>
          </Modal.Body>
        </Modal>

        <Modal show={showModal4} onHide={handleClose4}>
          <Modal.Header>
            <Modal.Title>Preview</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img src={inputs.supportDocsBank4}></img>
          </Modal.Body>
        </Modal>

        <Modal show={showModal5} onHide={handleClose5}>
          <Modal.Header>
            <Modal.Title>Preview</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img src={inputs.supportDocsBank5}></img>
          </Modal.Body>
        </Modal>

        <Modal show={showModal6} onHide={handleClose6}>
          <Modal.Header>
            <Modal.Title>Preview</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img src={inputs.supportDocsBank6}></img>
          </Modal.Body>
        </Modal>

        <Modal show={showNotVatModal} onHide={handleCloseNoVAT}>
          <Modal.Header>
            <Modal.Title>Preview</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img src={inputs.supportNoVAT}></img>
          </Modal.Body>
        </Modal>

        <Modal show={isCopying} centered>
          <Modal.Body>
            <Row>
              <div className="col-auto">
                <Spinner animation="border" variant="warning" />
              </div>
              <div className="text-left">
                <span className="align-middle">&nbsp;Please wait while we fetch your data</span>
              </div>
            </Row>
          </Modal.Body>
        </Modal>
        <Modal show={showErrorModal} onHide={() => setShowErrorModal(false)} centered>
          <Modal.Header>
            <Modal.Title>Warning</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>The file size is greater than 1MB limit.</Col>
            </Row>
            <Row>
              <Col>Please reduce the file size and try again.</Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Row>
              <div className="col col-md"></div>
              <div className="col-auto col-md-auto text-right">
                <Button
                  className="btn-danger btn-block"
                  style={{ textDecoration: 'none' }}
                  onClick={() => {
                    setShowErrorModal(false);
                  }}
                >
                  OK
                </Button>
              </div>
            </Row>
          </Modal.Footer>
        </Modal>
        <div className="card-header">
          <div className="col-11 col-lg-12">
            <h3 className="card-title text-left">
              {/* <strong>Customer Data {getLang('Creation')}</strong> - {getLang('Payer')} */}
              <strong style={{ color: 'rgb(177, 31, 36)' }}>
                {getLang('General Customer Creation')}
              </strong>{' '}
              - {getLang('Payer')}
            </h3>
          </div>
          <a
            className={`sidebar-toggler col-auto ${isSidebarOpen ? '' : 'collapsed'}`}
            // onClick={togglingSidebar}
          >
            <Sidebar sidebarItems={sidebarItems} />
            {/* <span className="sidebar-toggler-icon"></span> */}
          </a>
        </div>
        <Row className="sidebar-row">
          <div
            className={`col-12 col-lg-2 text-left sidebar ${
              isMobile ? (isSidebarOpen ? '' : 'collapse') : ''
            }`}
          >
            <Row>
              <ButtonGroup vertical className="btn-block">
                <Link
                  data-link="mainpage"
                  data-back="true"
                  className="btn btn-warning"
                  onClick={handleSubmit}
                >
                  {getLang('Main Page')}
                </Link>
                <Link
                  data-link="shipto"
                  data-back="true"
                  className="btn btn-warning"
                  onClick={handleSubmit}
                >
                  {'Ship To'}
                </Link>
                <Button className="btn-danger">{getLang('Payer')}</Button>
                <Link data-link="soldto" className="btn btn-warning" onClick={handleSubmit}>
                  {getLang('Sold To')}
                </Link>
                <Link data-link="billto" className="btn btn-warning" onClick={handleSubmit}>
                  {getLang('Bill To')}
                </Link>
              </ButtonGroup>
            </Row>
          </div>
          <div className="col-12 col-lg-10">
            <div className="card-body">
              <MandatoryText text={getLang(`Fields marked with an asterisk (*) are required`)} />

              <div className="row">
                <div className="col-lg-12">
                  {shipToType != 'Wholesaler' && (
                    <>
                      <div className="row">
                        <div className="col-12 col-lg-4">
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Existing Payer ID')}
                            </label>
                            <div className="input-group">
                              <input
                                type="text"
                                className={`form-control${
                                  errors.existingPayerID ? ' is-invalid' : ''
                                }`}
                                name="existingPayerID"
                                maxLength="8"
                                placeholder={`Enter ${getLang('Existing Payer ID')}`}
                                value={inputs.existingPayerID}
                                onChange={setInputs}
                                disabled={isDisable}
                              />
                              <span className="input-group-append">
                                <button
                                  className="btn btn-primary"
                                  type="button"
                                  style={{
                                    borderTopRightRadius: '3px',
                                    borderBottomRightRadius: '3px',
                                    marginTop: '-1px',
                                    height: '2.33rem',
                                  }}
                                  onClick={copyPayerData}
                                  disabled={isDisable || inputs.existingPayerID === ''}
                                >
                                  {getLang('Copy')}
                                </button>
                              </span>
                              {errors.existingPayerID && (
                                <div className="invalid-feedback text-left">
                                  {errors.existingPayerID}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <hr />
                    </>
                  )}

                  <div className="row">
                    <div className="col-12 col-lg-auto text-left">
                      <h2
                        style={{
                          color: 'rgb(227, 175, 50)',
                        }}
                      >
                        {getLang('General Data')}
                      </h2>
                    </div>
                  </div>
                  <div className="row">
                    {!['Norway', 'Switzerland', 'United Kingdom', 'Netherlands'].includes(
                      country,
                    ) && (
                      <div className="col-12 col-lg-4">
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('Title')}
                            {['Italy'].includes(country) ? ' *' : ''}
                          </label>
                          <select
                            id="select-title"
                            className={`form-control ${errors.title ? 'is-invalid' : ''}`}
                            name="title"
                            value={inputs.title}
                            onChange={setInputs}
                            disabled={isDisableFields || isDisable}
                          >
                            <option value="">Select</option>
                            {titlesRes.titles.map((val) => (
                              <option key={val.title} value={val.title} data-title={val.title}>
                                {val.title}
                              </option>
                            ))}
                          </select>
                          {errors.title && (
                            <div className="invalid-feedback text-left">
                              {getLang(errors.title)}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    {(country === 'Belgium' && !shipToType.includes('Wholesaler')) ||
                    [
                      'Netherlands',
                      'France',
                      'Norway',
                      'Sweden',
                      'Finland',
                      'Denmark',
                      'Austria',
                      'Switzerland',
                      'United Kingdom',
                      'Germany',
                    ].includes(country) ? (
                      <div className="col-12 col-lg-4">
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('Does the customer have a VAT number?')}
                          </label>
                          <select
                            className="form-control"
                            name="vatExist"
                            value={inputs.vatExist}
                            onChange={setInputs}
                            disabled={isDisableFields || isDisable}
                          >
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                            {country !== 'Netherlands' && (
                              <option value="In Progress">In Progress</option>
                            )}
                          </select>
                        </div>
                      </div>
                    ) : null}
                    {[
                      'Austria',
                      'Denmark',
                      'France',
                      'Germany',
                      'Italy',
                      'Luxembourg',
                      'Sweden',
                      'Switzerland',
                    ].includes(country) && (
                      <CustomTextInput
                        title={
                          country != 'France'
                            ? 'Tax Number'
                            : country == 'France' &&
                              [
                                'On-Trade – Direct',
                                'On-Trade – Indirect',
                                'Off-Trade – Direct',
                                'Off-Trade - Indirect',
                              ].includes(shipToType)
                            ? 'Tax Number 1 (SIREN)'
                            : 'Tax Number 1 (SIRET)'
                        }
                        name="taxNumber"
                        mandatory={
                          country == 'France' ? true : inputs.ediOption == 'Yes' ? true : false
                        }
                        inputs={inputs}
                        errors={errors}
                        onChange={setInputs}
                        setInputs={setInputs}
                        maxLength={
                          country === 'Netherlands'
                            ? 14
                            : country === 'United Kingdom'
                            ? 10
                            : country == 'France'
                            ? 9
                            : 13
                        }
                        placeholder="Tax Number"
                        disabled={isDisable}
                        getLang={getLang}
                      />
                    )}
                    {['France'].includes(country) && (
                      <CustomTextInput
                        title={
                          country == 'France' &&
                          [
                            'On-Trade – Direct',
                            'On-Trade – Indirect',
                            'Off-Trade – Direct',
                            'Off-Trade - Indirect',
                          ].includes(shipToType)
                            ? 'Tax Number 2 (SIRET)'
                            : 'Tax Number 2 (SIREN)'
                        }
                        name="taxNumber2"
                        mandatory={
                          country == 'France' ? true : inputs.ediOption == 'Yes' ? true : false
                        }
                        inputs={inputs}
                        errors={errors}
                        onChange={setInputs}
                        setInputs={setInputs}
                        maxLength={14}
                        placeholder="Tax Number 2"
                        disabled={isDisable}
                        getLang={getLang}
                      />
                    )}
                    {/* {['Netherlands'].includes(inputs.payerCountry) && (
                      <div className="col-12 col-lg-4">
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('Does Customer have VAT')} *
                          </label>
                          <select
                            className="form-control"
                            name="isVAT"
                            value={inputs.isVAT}
                            onChange={setInputs}
                            disabled={isDisableFields || isDisable}
                          >
                            <option value="">Select</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                        </div>
                      </div>
                    )} */}
                    <CustomTextInput
                      title="VAT Registration No"
                      name="vatRegNumber"
                      mandatory={
                        country == 'United Kingdom' && shipToType == 'On-Trade – Indirect'
                          ? false
                          : country == 'United Kingdom' ||
                            [
                              'Austria',
                              'Denmark',
                              'Finland',
                              'Italy',
                              'Luxembourg',
                              'Norway',
                              'Poland',
                              'Sweden',
                              'Switzerland',
                            ].includes(country) ||
                            (country == 'Netherlands' &&
                              mainPageData.customerReqType != 'Prospect') ||
                            (country == 'France' && inputs.title != 'ASBL') ||
                            (country == 'Belgium' && shipToType == 'Off-Trade – Direct') ||
                            inputs.vatExist == 'Yes'
                          ? true
                          : false
                      }
                      inputs={inputs}
                      errors={errors}
                      onChange={setInputs}
                      setInputs={setInputs}
                      maxLength={vatLength(inputs.payerCountry)}
                      placeholder={vatPlaceholder(inputs.payerCountry)}
                      disabled={
                        isDisableFields ||
                        isDisable ||
                        (country == 'Netherlands' && inputs.vatExist == 'No')
                      }
                      getLang={getLang}
                    />
                    {inputs.vatExist == 'No' && country == 'Netherlands' && (
                      <div className="col-12 col-lg-4">
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('Reason for no VAT?')}
                          </label>
                          {!inputs.supportNoVAT && (
                            <input
                              type="file"
                              id="upload-support-docs"
                              style={{ color: 'transparent' }}
                              accept="image/png, image/jpeg, .doc, .docx, application/msword, .msg, .pdf, .zip"
                              name="supportNoVAT"
                              onChange={(e, action) => {
                                FileSize(e, action);
                                setInputs(e);
                              }}
                              disabled={isDisableFields || isDisable}
                            />
                          )}
                          {inputs.supportNoVAT && (
                            <div className="form-control-textfield text-left">
                              <a
                                href=""
                                onClick={(e) => {
                                  e.preventDefault();
                                  if (
                                    ['.jpeg', '.jpg', '.png'].includes(
                                      fileRegex.exec(inputs.supportNoVATName)[0],
                                    )
                                  ) {
                                    setShowNotVatModal(true);
                                  }
                                }}
                              >
                                {inputs.supportNoVATName}
                              </a>
                              &nbsp;
                              <FeatherIcon
                                id="clearFileNoVat"
                                icon="x"
                                size={14}
                                name="clearFileNotVat"
                                style={{ cursor: 'pointer' }}
                                onClick={setInputs}
                                className="fe-icon"
                              />
                            </div>
                          )}
                          {errors.supportNoVAT && (
                            <div className="invalid-feedback-file text-left">
                              {errors.supportNoVAT}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    <CustomTextInput
                      title={
                        ['United Kingdom', 'Netherlands'].includes(country)
                          ? getLang('Legal Entity / Name')
                          : country == 'France'
                          ? getLang('NamePayer')
                          : getLang('Name')
                      }
                      name="name"
                      mandatory={true}
                      inputs={inputs}
                      errors={errors}
                      onChange={setInputs}
                      setInputs={setInputs}
                      maxLength="35"
                      placeholder="Name"
                      disabled={isDisableFields || isDisable}
                      getLang={getLang}
                    />
                    <CustomTextInput
                      title={getLang('Name') + '2'}
                      name="name2"
                      mandatory={false}
                      inputs={inputs}
                      errors={errors}
                      onChange={setInputs}
                      setInputs={setInputs}
                      maxLength="35"
                      placeholder="Name 2"
                      disabled={isDisableFields || isDisable}
                      getLang={getLang}
                    />
                    {['United Kingdom', 'Netherlands'].includes(country) && (
                      <CustomTextInput
                        title={
                          country === 'United Kingdom'
                            ? getLang('Company number')
                            : getLang('KVK number')
                        }
                        name="companyNumber"
                        mandatory={
                          ['Netherlands'].includes(country) && customerReqType == 'Prospect'
                            ? false
                            : true
                        }
                        inputs={inputs}
                        errors={errors}
                        onChange={setInputs}
                        setInputs={setInputs}
                        maxLength="8"
                        placeholder=""
                        disabled={isDisableFields || isDisable}
                        getLang={getLang}
                      />
                    )}
                    <CustomTextInput
                      title="Postal Code"
                      name="postalCode"
                      mandatory={true}
                      inputs={inputs}
                      errors={errors}
                      onChange={setInputs}
                      setInputs={setInputs}
                      maxLength={postalCodeLength(inputs.payerCountry)}
                      placeholder=""
                      disabled={isDisableFields || isDisable}
                      getLang={getLang}
                    />
                    <CustomTextInput
                      title="Street"
                      name="street"
                      mandatory={true}
                      inputs={inputs}
                      errors={errors}
                      onChange={setInputs}
                      setInputs={setInputs}
                      maxLength="35"
                      placeholder=""
                      disabled={isDisableFields || isDisable}
                      getLang={getLang}
                    />
                    <CustomTextInput
                      title="House Number"
                      name="houseNumber"
                      mandatory={
                        country !== 'United Kingdom' && country !== 'France' ? true : false
                      }
                      inputs={inputs}
                      errors={errors}
                      onChange={setInputs}
                      setInputs={setInputs}
                      maxLength="10"
                      placeholder=""
                      disabled={isDisableFields || isDisable}
                      getLang={getLang}
                    />
                    <CustomTextInput
                      title="City"
                      name="city"
                      mandatory={true}
                      inputs={inputs}
                      errors={errors}
                      onChange={setInputs}
                      setInputs={setInputs}
                      maxLength="35"
                      placeholder=""
                      disabled={isDisableFields || isDisable}
                      getLang={getLang}
                    />

                    <div className="col-12 col-lg-4">
                      <div className="form-group">
                        <label className="form-label text-left">
                          {getLang('Country')}
                          {country == 'Italy' ? ' *' : ''}
                        </label>
                        {/* <input type="text" className="form-control" name="country" value={country} readOnly /> */}
                        <select
                          className={`form-control ${errors.payerCountry ? 'is-invalid' : ''}`}
                          name="payerCountry"
                          onChange={setInputs}
                          value={inputs.payerCountry}
                          required
                          disabled={isDisableFields || isDisable}
                        >
                          <option value="">Select</option>
                          {/* {countriesRes.countries.filter(e => ['France', 'Finland', 'Belgium', 'Netherlands', 'United Kingdom', 'Germany'].includes(e.country)).map((val) => (
                            <option key={val.countryCode} value={val.country}>
                              {val.country}
                            </option>
                          ))} */}
                          {countriesRes.countries.map((val) => (
                            <option key={val.countryCode} value={val.country}>
                              {val.country}
                            </option>
                          ))}
                        </select>
                        {errors.payerCountry && (
                          <div className="invalid-feedback text-left">{errors.payerCountry}</div>
                        )}
                      </div>
                    </div>
                    {inputs.payerCountry != 'Luxembourg' && (
                      <RegionSelect
                        errors={errors}
                        inputs={inputs}
                        setInputs={setInputs}
                        getLang={getLang}
                        regionOptions={regionOptions}
                        isDisable={isDisable}
                        isDisableFields={isDisableFields}
                      />
                    )}
                    <LangSelect
                      errors={errors}
                      inputs={inputs}
                      setInputs={setInputs}
                      getLang={getLang}
                      languageOptions={languageOptions}
                      isDisable={isDisable}
                      isDisableFields={isDisableFields || customerReqType == '2nd Sold To'}
                    />
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-12 col-lg-auto text-left">
                      <h2
                        style={{
                          color: 'rgb(227, 175, 50)',
                        }}
                      >
                        {getLang('Communication')}
                      </h2>
                    </div>
                  </div>
                  <div className="row">
                    <CustomTelInput
                      title="Telephone/Fax numbers"
                      name={'telephone'}
                      errors={errors}
                      inputs={inputs}
                      placeholder={telephonePlaceholder(country)}
                      setInputs={setInputs}
                      onChange={setInputs}
                      maxLength={telephoneLength(country)}
                      getLang={getLang}
                      mandatory={
                        [
                          'Netherlands',
                          'Austria',
                          'Denmark',
                          'Finland',
                          'Italy',
                          'Norway',
                          'Poland',
                          'Sweden',
                          'Switzerland',
                          'Belgium',
                        ].includes(country)
                          ? true
                          : false
                      }
                      disabled={isDisableFields || isDisable}
                    />
                    <CustomTelInput
                      title="Mobile"
                      name={'mobile'}
                      errors={errors}
                      inputs={inputs}
                      placeholder={mobilePlaceholder(country)}
                      setInputs={setInputs}
                      onChange={setInputs}
                      maxLength={mobileLength(country)}
                      getLang={getLang}
                      mandatory={
                        ['Netherlands', 'United Kingdom', 'Italy'].includes(country) ? true : false
                      }
                      disabled={isDisableFields || isDisable}
                    />

                    <CustomTextInput
                      title="E-mail Address"
                      name={'email'}
                      errors={errors}
                      inputs={inputs}
                      placeholder="Eg.: abc@company.com"
                      setInputs={setInputs}
                      onChange={setInputs}
                      maxLength="240"
                      getLang={getLang}
                      mandatory={true}
                      disabled={isDisableFields || isDisable}
                    />
                    {([
                      'United Kingdom',
                      'France',
                      'Belgium',
                      'Finland',
                      'Norway',
                      'Sweden',
                      'Germany',
                      'Denmark',
                      'Switzerland',
                      'Austria',
                    ].includes(country) ||
                      (['Luxembourg', 'Netherlands'].includes(country) &&
                        !shipToType.startsWith('Off-Trade'))) && (
                      <div className="col-12 col-lg-4">
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('Yearly Volume Forecast')}
                            {` (HL)`}
                            {['Belgium', 'Netherlands', 'Luxembourg', 'France'].includes(country) &&
                            [
                              'On-Trade – Direct',
                              'National On-Trade Direct',
                              'Wholesale– Direct',
                            ].includes(shipToType)
                              ? ' *'
                              : ''}
                          </label>
                          <input
                            type="number"
                            className={`form-control ${
                              errors.yearlyVolForecastHL ? 'is-invalid' : ''
                            }`}
                            name="yearlyVolForecastHL"
                            placeholder="Eg.: 120"
                            value={inputs.yearlyVolForecastHL}
                            onChange={setInputs}
                            maxLength="35"
                            disabled={
                              isDisableFields ||
                              isDisable ||
                              (country == 'Belgium' &&
                                ![
                                  'Off-Trade – Direct',
                                  'On-Trade – Direct',
                                  'National On-Trade Direct',
                                  'Wholesale– Direct',
                                ].includes(shipToType)) ||
                              mainPageData.customerReqType == '2nd Sold To'
                            }
                          />
                          {errors.yearlyVolForecastHL && (
                            <div className="invalid-feedback text-left">
                              {errors.yearlyVolForecastHL}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    <div className="col-12 col-lg-4">
                      <div className="form-group">
                        <label className="form-label text-left">
                          {getLang('Contact Person')} {country == 'Belgium' ? ' *' : ''}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errors.contactPerson ? 'is-invalid' : ''}`}
                          name="contactPerson"
                          value={inputs.contactPerson}
                          onChange={setInputs}
                          maxLength="35"
                          disabled={isDisableFields || isDisable}
                        />
                        {errors.contactPerson && (
                          <div className="invalid-feedback text-left">{errors.contactPerson}</div>
                        )}
                      </div>
                    </div>
                    {!['Italy'].includes(inputs?.payerCountry) && (
                      <div className="col-12 col-lg-4">
                        <div className="form-group">
                          <label className="form-label text-left">{getLang('E-Invoicing')}</label>
                          <select
                            className="form-control"
                            name="eInvOption"
                            value={inputs.eInvOption}
                            onChange={eInvOptionChange}
                            disabled={
                              isDisableFields ||
                              isDisable ||
                              inputs.payerCountry == 'Italy' ||
                              country == 'Sweden' ||
                              country == 'Norway'
                            }
                          >
                            <option value="">Select</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                          {errors.eInvOption && (
                            <div className="invalid-feedback text-left">{errors.eInvOption}</div>
                          )}
                        </div>
                      </div>
                    )}
                    {!['Italy'].includes(inputs?.payerCountry) && inputs.eInvOption === 'Yes' && (
                      <>
                        {/* <div className="col-12 col-lg-4">
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('E-Invoicing Email')} Type{` *`}
                            </label>
                            <select
                              className={`form-control ${errors.eInvEmailType ? 'is-invalid' : ''}`}
                              name="eInvEmailType"
                              value={inputs.eInvEmailType}
                              onChange={setInputs}
                              disabled={
                                isDisableFields || isDisable || inputs.payerCountry == 'Italy'
                              }
                            >
                              <option value="">Select</option>
                              {einvoicingOptions.map((opt) => (
                                <option key={opt} value={opt}>
                                  {opt}
                                </option>
                              ))}
                            </select>
                            {errors.eInvEmailType && (
                              <div className="invalid-feedback text-left">
                                {errors.eInvEmailType}
                              </div>
                            )}
                          </div>
                        </div> */}
                        {inputs.eInvEmailType != '' && (
                          <div className="col-12 col-lg-4">
                            <div className="form-group">
                              <label className="form-label text-left">
                                {getLang('E-Invoicing Email')}
                              </label>
                              {inputs.eInvEmailType === 'General/Accounting' && (
                                <input
                                  type="email"
                                  className={`form-control ${errors.eInvEmail ? 'is-invalid' : ''}`}
                                  name="eInvGeneralEmail"
                                  placeholder="Enter the invoicing email"
                                  value={inputs.eInvGeneralEmail}
                                  onChange={setInputs}
                                  maxLength="240"
                                  disabled={isDisableFields || isDisable}
                                />
                              )}
                              {inputs.eInvEmailType === 'Logistics' && (
                                <input
                                  type="email"
                                  className={`form-control ${errors.eInvEmail ? 'is-invalid' : ''}`}
                                  name="eInvLogEmail"
                                  placeholder="Enter the Logistics email"
                                  value={inputs.eInvLogEmail}
                                  onChange={setInputs}
                                  maxLength="240"
                                  disabled={isDisableFields || isDisable}
                                />
                              )}
                              {inputs.eInvEmailType === 'Invoicing' && (
                                <input
                                  type="email"
                                  className={`form-control ${errors.eInvEmail ? 'is-invalid' : ''}`}
                                  name="eInvInvEmail"
                                  placeholder="Enter the Invoicing email"
                                  value={inputs.eInvInvEmail}
                                  onChange={setInputs}
                                  maxLength="240"
                                  disabled={isDisableFields || isDisable}
                                />
                              )}
                              {inputs.eInvEmailType === 'Ordering' && (
                                <input
                                  type="email"
                                  className={`form-control ${errors.eInvEmail ? 'is-invalid' : ''}`}
                                  name="eInvOrderEmail"
                                  placeholder="Enter the Ordering email"
                                  value={inputs.eInvOrderEmail}
                                  onChange={setInputs}
                                  maxLength="240"
                                  disabled={isDisableFields || isDisable}
                                />
                              )}
                              {errors.eInvEmail && (
                                <div className="invalid-feedback text-left">{errors.eInvEmail}</div>
                              )}
                            </div>
                          </div>
                        )}
                      </>
                    )}
                    {!['Italy'].includes(inputs.payerCountry) && (
                      <div className="col-12 col-lg-4">
                        <div className="form-group">
                          <label className="form-label text-left">{getLang('Is Vendor')}</label>
                          <select
                            className="form-control"
                            name="isVendor"
                            value={inputs.isVendor}
                            onChange={setInputs}
                            disabled={
                              isDisableFields ||
                              isDisable ||
                              !(
                                ['Germany', 'Netherlands'].includes(country) ||
                                (country === 'Belgium' && shipToType !== 'Off-Trade - Indirect') ||
                                (country === 'France' &&
                                  (shipToType.includes('Wholesale') ||
                                    shipToType.includes('Direct')))
                              ) ||
                              mainPageData.customerReqType == '2nd Sold To'
                            }
                          >
                            <option value="">Select</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                        </div>
                      </div>
                    )}
                    {inputs.isVendor === 'Yes' && (
                      <div className="col-12 col-lg-4">
                        <div className="form-group">
                          <label className="form-label text-left">{getLang('Vendor No.')}</label>
                          <input
                            type="text"
                            className={`form-control ${errors.vendorNumber ? 'is-invalid' : ''}`}
                            name="vendorNumber"
                            value={inputs.vendorNumber}
                            onChange={setInputs}
                            maxLength="35"
                            disabled={isDisableFields || isDisable}
                          />
                          {errors.vendorNumber && (
                            <div className="invalid-feedback text-left">{errors.vendorNumber}</div>
                          )}
                        </div>
                      </div>
                    )}

                    {['Belgium', 'Netherlands', 'France', 'Luxembourg'].includes(country) && (
                      <div className="col-12 col-lg-4">
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('EDI')}
                            {['France'].includes(country) &&
                            ['Off-Trade – Direct', 'Off-Trade - Indirect'].includes(shipToType)
                              ? ' *'
                              : ''}
                          </label>
                          <select
                            className={`form-control ${errors.ediOption ? 'is-invalid' : ''}`}
                            name="ediOption"
                            value={inputs.ediOption}
                            onChange={setInputs}
                            disabled={
                              isDisableFields ||
                              (country == 'Netherlands' && !shipToType.includes('National')) ||
                              (country == 'Belgium' && !shipToType.startsWith('National')) ||
                              isDisable ||
                              customerReqType == '2nd Sold To'
                            }
                          >
                            <option value="">Select</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                          {errors.ediOption && (
                            <div className="invalid-feedback text-left">
                              {getLang(errors.ediOption)}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    {inputs.ediOption === 'Yes' && (
                      <>
                        <CustomTextInput
                          title="GLN/ILN CODE"
                          name={'glnIlnCode'}
                          errors={errors}
                          inputs={inputs}
                          placeholder="Eg: 1234567890123"
                          setInputs={setInputs}
                          onChange={setInputs}
                          maxLength="13"
                          getLang={getLang}
                          mandatory={true}
                          disabled={
                            isDisableFields || isDisable || customerReqType == '2nd Sold To'
                          }
                        />
                        <CustomTextInput
                          title="EDI Email Address"
                          name={'ediEmail'}
                          errors={errors}
                          inputs={inputs}
                          placeholder="Eg.: abc@company.com"
                          setInputs={setInputs}
                          onChange={setInputs}
                          maxLength="240"
                          getLang={getLang}
                          mandatory={true}
                          disabled={
                            isDisableFields || isDisable || customerReqType == '2nd Sold To'
                          }
                        />
                      </>
                    )}
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-12 col-lg-auto text-left">
                      <h2
                        style={{
                          color: 'rgb(177, 31, 36)',
                        }}
                      >
                        {getLang('Bank and Tax Details')}
                      </h2>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 text-left color-text">
                      <p>
                        <strong>
                          {getLang(
                            'Bank Card, Bank Statement, Letter head from Bank with Seal and Signature is what constitutes a Valid Bank proof that needs to be attached irrespective of the customer type. (Attachment limit is 1 MB)',
                          )}
                        </strong>
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-lg-4">
                      <div className="form-group">
                        <label className="form-label text-left">
                          {getLang('IBAN')}
                          {['Austria', 'Italy', 'Norway', 'Poland', 'Switzerland'].includes(
                            country,
                          ) ||
                          (country == 'Netherlands' &&
                            ['Full Creation', 'New Owner'].includes(customerReqType)) ||
                          (country == 'United Kingdom' && customerReqType == 'Listing Fees') ||
                          (country == 'France' &&
                            [
                              'Wholesale– Direct',
                              'Wholesale– Indirect',
                              'Off-Trade – Direct',
                              'Off-Trade - Indirect',
                            ].includes(shipToType))
                            ? ' *'
                            : ''}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errors.iban ? 'is-invalid' : ''}`}
                          name="iban"
                          maxLength={ibanLength(country)}
                          placeholder={ibanPlaceholder(country)}
                          value={inputs.iban}
                          onChange={setInputs}
                          disabled={isDisableFields || isDisable}
                        />
                        {errors.iban && (
                          <div className="invalid-feedback text-left">{errors.iban}</div>
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-lg-4">
                      <div className="form-group">
                        <label className="form-label text-left">
                          {getLang('Account Holder Name')}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="accHolderName"
                          value={inputs.accHolderName}
                          onChange={setInputs}
                          maxLength="35"
                          disabled={isDisableFields || isDisable}
                        />
                      </div>
                    </div>
                    {!['Italy'].includes(country) && (
                      <>
                        <div className="col-12 col-lg-4">
                          <div className="form-group">
                            <label className="form-label text-left">{getLang('Bank Key')}</label>
                            <input
                              type="text"
                              className="form-control"
                              name="bankKey"
                              value={inputs.bankKey}
                              onChange={setInputs}
                              maxLength="35"
                              disabled={isDisableFields || true}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-lg-4">
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Bank Account')}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="bankAcc"
                              value={inputs.bankAcc}
                              onChange={setInputs}
                              maxLength="35"
                              disabled={isDisableFields || true}
                            />
                          </div>
                        </div>
                      </>
                    )}
                    <div className="col-12 col-lg-4">
                      <div className="form-group">
                        <label className="form-label text-left">
                          {getLang('SWIFT/ BIC Code')}
                          {['Italy'].includes(country) ||
                          (country == 'France' &&
                            [
                              'Wholesale– Direct',
                              'Wholesale– Indirect',
                              'Off-Trade – Direct',
                              'Off-Trade - Indirect',
                            ].includes(shipToType))
                            ? ' *'
                            : ''}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errors.swiftBicCode ? 'is-invalid' : ''}`}
                          maxLength="30"
                          name="swiftBicCode"
                          value={inputs.swiftBicCode}
                          onChange={setInputs}
                          disabled={isDisableFields || isDisable}
                        />
                        {errors.swiftBicCode && (
                          <div className="invalid-feedback text-left">{errors.swiftBicCode}</div>
                        )}
                      </div>
                    </div>
                    {/* Attachment goes here */}
                    <div className="col-12 col-lg-4">
                      <div className="form-group">
                        <label className="form-label text-left">
                          {getLang('Please Attach The Supportings For IBAN & Bank Details')}
                          {['France'].includes(country) && inputs.iban != '' ? ' *' : ''}
                        </label>
                        {!inputs.supportDocsBank && (
                          <input
                            type="file"
                            id="upload-support-docs"
                            style={{ color: 'transparent' }}
                            accept="image/png, image/jpeg, .doc, .docx, application/msword, .msg, .pdf, .zip"
                            name="supportDocsBank"
                            onChange={(e, action) => {
                              FileSize(e, action);
                              setInputs(e);
                            }}
                            disabled={isDisableFields || isDisable}
                          />
                        )}
                        {inputs.supportDocsBank && (
                          <div className="form-control-textfield text-left">
                            <a
                              href=""
                              onClick={(e) => {
                                e.preventDefault();
                                if (
                                  ['.jpeg', '.jpg', '.png'].includes(
                                    fileRegex.exec(inputs.supportDocsBankName)[0],
                                  )
                                ) {
                                  setShowModal(true);
                                }
                              }}
                            >
                              {inputs.supportDocsBankName}
                            </a>
                            &nbsp;
                            <FeatherIcon
                              id="clearFile"
                              icon="x"
                              size={14}
                              name="clearFile"
                              style={{ cursor: 'pointer' }}
                              onClick={setInputs}
                              className="fe-icon"
                            />
                          </div>
                        )}
                        {errors.supportDocsBank && (
                          <div className="invalid-feedback-file text-left">
                            {errors.supportDocsBank}
                          </div>
                        )}
                      </div>
                    </div>

                    {
                      <>
                        <div className="col-12 col-lg-4">
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Attachment 2')}
                            </label>
                            {!inputs.supportDocsBank1 && (
                              <input
                                type="file"
                                style={{ color: 'transparent' }}
                                accept="image/png, image/jpeg, .doc, .docx, application/msword, .msg, .pdf, .zip"
                                name="supportDocsBank1"
                                onChange={(e, action) => {
                                  FileSize(e, action);
                                  setInputs(e);
                                }}
                                disabled={
                                  isDisableFields ||
                                  isDisable ||
                                  mainPageData.customerReqType == '2nd Sold To'
                                }
                              />
                            )}
                            {inputs.supportDocsBank1 && (
                              <div className="form-control-textfield text-left">
                                <a
                                  href=""
                                  onClick={(e) => {
                                    e.preventDefault();
                                    if (
                                      ['.jpeg', '.jpg', '.png'].includes(
                                        fileRegex.exec(inputs.supportDocsBankName1)[0],
                                      )
                                    ) {
                                      setShowModal1(true);
                                    }
                                  }}
                                >
                                  {inputs.supportDocsBankName1}
                                </a>
                                &nbsp;
                                <FeatherIcon
                                  id="clearFile1"
                                  icon="x"
                                  size={14}
                                  name="clearFile1"
                                  style={{ cursor: 'pointer' }}
                                  onClick={setInputs}
                                  className="fe-icon"
                                />
                              </div>
                            )}
                            {errors.supportDocsBank1 && (
                              <div className="invalid-feedback-file text-left">
                                {errors.supportDocsBank1}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="col-12 col-lg-4">
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Attachment 3')}
                            </label>
                            {!inputs.supportDocsBank2 && (
                              <input
                                type="file"
                                style={{ color: 'transparent' }}
                                accept="image/png, image/jpeg, .doc, .docx, application/msword, .msg, .pdf, .zip"
                                name="supportDocsBank2"
                                onChange={(e, action) => {
                                  FileSize(e, action);
                                  setInputs(e);
                                }}
                                disabled={
                                  isDisableFields ||
                                  isDisable ||
                                  mainPageData.customerReqType == '2nd Sold To'
                                }
                              />
                            )}
                            {inputs.supportDocsBank2 && (
                              <div className="form-control-textfield text-left">
                                <a
                                  href=""
                                  onClick={(e) => {
                                    e.preventDefault();
                                    if (
                                      ['.jpeg', '.jpg', '.png'].includes(
                                        fileRegex.exec(inputs.supportDocsBankName2)[0],
                                      )
                                    ) {
                                      setShowModal2(true);
                                    }
                                  }}
                                >
                                  {inputs.supportDocsBankName2}
                                </a>
                                &nbsp;
                                <FeatherIcon
                                  id="clearFile2"
                                  icon="x"
                                  size={14}
                                  name="clearFile2"
                                  style={{ cursor: 'pointer' }}
                                  onClick={setInputs}
                                  className="fe-icon"
                                />
                              </div>
                            )}
                            {errors.supportDocsBank2 && (
                              <div className="invalid-feedback-file text-left">
                                {errors.supportDocsBank2}
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    }
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-12 col-lg-auto text-left">
                      <h2
                        style={{
                          color: 'rgb(177, 31, 36)',
                        }}
                      >
                        {getLang('Company Data')}
                      </h2>
                    </div>
                  </div>
                  <div className="row">
                    {/* Get a state variable to define disabled and value in case of On-Trade – Indirect, Off-Trade - Retail, TPD2/ TPD3, Miscellaneous. 
                                Just make a new select based on the country */}
                    <div className="col-12 col-lg-4">
                      {/* <Switch
                        checked={inputs.creditBlock}
                        name="creditBlock"
                        onChange={handleCreditBlock}
                        onLabel="Yes"
                        offLabel="No"
                        label="Block on Credit ?"
                        size="lg"
                        labelPosition="left"
                        // labelPlacement="top"
                        color="yellow"
                        disabled={isDisable || inputs.existingSoldToID == ''}
                      /> */}
                      <div className="form-group">
                        <label className="form-label text-left">
                          {getLang('Terms of payment')}
                          {[
                            'Belgium',
                            'Austria',
                            'Denmark',
                            'Finland',
                            'Italy',
                            'Luxembourg',
                            'Norway',
                            'Poland',
                            'Sweden',
                            'Switzerland',
                            'Netherlands',
                            'France',
                          ].includes(country)
                            ? ' *'
                            : ''}
                        </label>
                        {/* <select id="select-terms-payment" className="form-control" name="paymentTerms" value={inputs.paymentTerms} onChange={setInputs} disabled={isDisableFields || isDisable || ['Belgium', 'United Kingdom', 'Netherlands'].includes(country) && shipToType.endsWith('Indirect')}>
                          <option value="0000">0000</option>
                          {paymentTermsRes.paymentTerms.map((val) => (
                            <option key={val.termsOfPayment} value={val.termsOfPayment}>{`${val.termsOfPayment} - ${val.paymentDesc}`}</option>
                          ))}
                        </select> */}
                        <Select
                          className="text-left"
                          name="paymentTerms"
                          value={paymentTermsOptions.filter(
                            (opt) => opt.termsOfPayment == inputs.paymentTerms,
                          )}
                          theme={(theme) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary25: '#cfcfcf',
                              primary50: '#cfcfcf',
                              primary: 'orange',
                            },
                          })}
                          isClearable
                          placeholder="Select"
                          isDisabled={
                            isDisableFields ||
                            isDisable ||
                            country == 'France' ||
                            (['Belgium', 'United Kingdom', 'Netherlands'].includes(country) &&
                              shipToType.endsWith('Indirect')) ||
                            mainPageData.customerReqType == '2nd Sold To'
                          }
                          getOptionLabel={({ paymentVal }) => paymentVal}
                          getOptionValue={({ termsOfPayment }) => termsOfPayment}
                          options={paymentTermsOptions}
                          onChange={(e, action) => {
                            setInputs(e, action);
                          }}
                          menuPlacement="top"
                        />
                        {errors.paymentTerms && (
                          <div className="invalid-feedback-file text-left">
                            {errors.paymentTerms}
                          </div>
                        )}
                      </div>
                    </div>

                    {country == 'Belgium' &&
                      shipToType.includes('Direct') &&
                      shipToType != 'National On-Trade Direct' && (
                        <div className="col-12 col-lg-4">
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('On-Invoicing Discount')}
                            </label>
                            <select
                              className="form-control"
                              name="onInvoicingDiscount"
                              value={inputs.onInvoicingDiscount}
                              onChange={setInputs}
                              disabled={
                                isDisableFields ||
                                isDisable ||
                                mainPageData.customerReqType == '2nd Sold To' ||
                                mainPageData.shipToType == 'National On-Trade Direct'
                              }
                            >
                              <option value="">Select</option>
                              <option value="Yes">{`Yes`}</option>
                              <option value="No">{`No`}</option>
                            </select>
                          </div>
                        </div>
                      )}

                    {country == 'Germany' && (
                      <div className="col-12 col-lg-4">
                        <div className="form-group">
                          <label className="form-label text-left">{getLang('Regulator')}</label>
                          <select
                            className="form-control"
                            name="regulator"
                            value={inputs.regulator}
                            onChange={setInputs}
                            disabled={isDisableFields || isDisable}
                          >
                            <option value="">Select</option>
                            <option value="REWE-ZENTRALFINANZ  E.G./ REWE FÜR SIE">{`REWE-ZENTRALFINANZ  E.G./ REWE FÜR SIE`}</option>
                            <option value="EDEKA ZENTRALE AG & CO.KG">{`EDEKA ZENTRALE AG & CO.KG`}</option>
                            <option value="GES - GROßEINKAUFSRING DES SÜßWAREN-UND GETRÄNKEHANDELS EG">{`GES - GROßEINKAUFSRING DES SÜßWAREN-UND GETRÄNKEHANDELS EG`}</option>
                            <option value="GEVA GMBH & CO. KG">{`GEVA GMBH & CO. KG`}</option>
                            <option value="TRINKS SÜD GMBH">{`TRINKS SÜD GMBH`}</option>
                            <option value="TRINKS GMBH ZENTRALE">{`TRINKS GMBH ZENTRALE`}</option>
                            <option value="MGP METRO GROUP ACCOUNT">{`MGP METRO GROUP ACCOUNT`}</option>
                            <option value="DBZ DIENSTLEISTUNGS UND BERATUNGSGESELLSCHAFT MBH">{`DBZ DIENSTLEISTUNGS UND BERATUNGSGESELLSCHAFT MBH`}</option>
                            <option value="MARKANT HANDELS UND SERVICE GMBH -ZENTRALREGULIERUNG">{`MARKANT HANDELS UND SERVICE GMBH -ZENTRALREGULIERUNG`}</option>
                            <option value="MARKANT HANDELS UND SERVICE GMBH - ABKOMMEN NR. 1">{`MARKANT HANDELS UND SERVICE GMBH - ABKOMMEN NR. 1`}</option>
                            <option value="M. HUBAUER GMBH & CO. KG">{`M. HUBAUER GMBH & CO. KG`}</option>
                            <option value="M. HUBAUER GMBH">{`M. HUBAUER GMBH`}</option>
                            <option value="WIFA GETRÄNKE-LOGISTIK">{`WIFA GETRÄNKE-LOGISTIK`}</option>
                          </select>
                        </div>
                      </div>
                    )}

                    {country == 'Italy' && (
                      <div className="col-12 col-lg-4">
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('Risk Category')}
                            {' *'}
                          </label>
                          <select
                            className={`form-control ${errors.riskCategory ? 'is-invalid' : ''}`}
                            name="riskCategory"
                            value={inputs.riskCategory}
                            onChange={setInputs}
                            disabled={
                              isDisableFields ||
                              isDisable ||
                              mainPageData.customerReqType == '2nd Sold To'
                            }
                          >
                            <option value="">Select</option>
                            {['A', 'B', 'C', 'D', 'F'].map((cat) => (
                              <option key={cat} value={cat}>
                                {cat}
                              </option>
                            ))}
                          </select>
                          {errors.riskCategory && (
                            <div className="invalid-feedback-file text-left">
                              {errors.riskCategory}
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                    {['Belgium', 'Netherlands'].includes(country) &&
                      shipToType.startsWith('On-Trade') && (
                        <div className="col-12 col-lg-4">
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Does the customer have rent?')}
                              {' *'}
                            </label>
                            <select
                              className={`form-control ${errors.rent ? 'is-invalid' : ''}`}
                              name="rent"
                              value={inputs.rent}
                              onChange={setInputs}
                              disabled={
                                isDisableFields ||
                                isDisable ||
                                mainPageData.customerReqType == '2nd Sold To'
                              }
                            >
                              <option value="">Select</option>
                              {['Yes', 'No'].map((val) => (
                                <option key={val} value={val}>
                                  {val}
                                </option>
                              ))}
                            </select>
                            {errors.rent && (
                              <div className="invalid-feedback-file text-left">{errors.rent}</div>
                            )}
                          </div>
                        </div>
                      )}

                    {['Netherlands'].includes(country) && (
                      <div className="col-12 col-lg-4">
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('Payment Method')}
                            {['Netherlands'].includes(country) ? ' *' : ''}
                          </label>
                          <Select
                            className="text-left"
                            name="paymentMethod"
                            value={paymentMethodOptions.filter(
                              (opt) => opt.paymentMethod == inputs.paymentMethod,
                            )}
                            theme={(theme) => ({
                              ...theme,
                              colors: {
                                ...theme.colors,
                                primary25: '#cfcfcf',
                                primary50: '#cfcfcf',
                                primary: 'orange',
                              },
                            })}
                            isClearable
                            placeholder="Select"
                            getOptionLabel={({ paymentMethod }) => paymentMethod}
                            getOptionValue={({ paymentMethod }) => paymentMethod}
                            options={paymentMethodOptions}
                            onChange={(e, action) => {
                              setInputs(e, action);
                            }}
                            menuPlacement="top"
                          />
                          {errors.paymentMethod && (
                            <div className="invalid-feedback-file text-left">
                              {errors.paymentMethod}
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                    {['Netherlands'].includes(country) && inputs.rent == 'No' && (
                      <div className="col-12 col-lg-4">
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('Reason why Direct Debit is not applicable')}
                            {['Netherlands'].includes(country) ? ' *' : ''}
                          </label>
                          <Select
                            className="text-left"
                            name="reasonDirectDebit"
                            value={reasonDirectDebitOptions.filter(
                              (opt) => opt.reasonDirectDebit == inputs.reasonDirectDebit,
                            )}
                            theme={(theme) => ({
                              ...theme,
                              colors: {
                                ...theme.colors,
                                primary25: '#cfcfcf',
                                primary50: '#cfcfcf',
                                primary: 'orange',
                              },
                            })}
                            isClearable
                            placeholder="Select"
                            getOptionLabel={({ reasonDirectDebit }) => reasonDirectDebit}
                            getOptionValue={({ reasonDirectDebit }) => reasonDirectDebit}
                            options={reasonDirectDebitOptions}
                            onChange={(e, action) => {
                              setInputs(e, action);
                            }}
                            menuPlacement="top"
                          />
                          {errors.reasonDirectDebit && (
                            <div className="invalid-feedback-file text-left">
                              {errors.reasonDirectDebit}
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                    <CustomTextInput
                      title="Reason for Payment Term"
                      name="ReasonPaymentTerm"
                      mandatory={
                        (['Netherlands'].includes(country) && inputs.paymentTerms == '028I') ||
                        inputs.paymentTerms == '030I'
                          ? true
                          : false
                      }
                      inputs={inputs}
                      errors={errors}
                      onChange={setInputs}
                      setInputs={setInputs}
                      maxLength="300"
                      placeholder="Reason for Payment Term"
                      disabled={isDisable}
                      getLang={getLang}
                    />

                    {['Italy'].includes(country) && (
                      <div className="col-12 col-lg-4">
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('SDI Code')}
                            {' *'}
                          </label>
                          <input
                            name="sdiCode"
                            type="text"
                            className={`form-control${errors.sdiCode ? ' is-invalid' : ''}`}
                            value={inputs.sdiCode}
                            onChange={setInputs}
                            maxLength="7"
                          />
                          {errors.sdiCode && (
                            <div className="invalid-feedback-file text-left">{errors.sdiCode}</div>
                          )}
                        </div>
                      </div>
                    )}
                    {
                      <>
                        {country == 'Belgium' && (
                          <div className="col-12 col-lg-4">
                            <div className="form-group">
                              <label className="form-label text-left">
                                {/* {getLang('Attachment 2')} */}
                                Payment Term Approval
                              </label>
                              {!inputs.supportDocsBank3 && (
                                <input
                                  type="file"
                                  style={{ color: 'transparent' }}
                                  accept="image/png, image/jpeg, .doc, .docx, application/msword, .msg, .pdf, .zip"
                                  name="supportDocsBank3"
                                  onChange={(e, action) => {
                                    FileSize(e, action);
                                    setInputs(e);
                                  }}
                                />
                              )}
                              {inputs.supportDocsBank3 && (
                                <div className="form-control-textfield text-left">
                                  <a
                                    href=""
                                    onClick={(e) => {
                                      e.preventDefault();
                                      if (
                                        ['.jpeg', '.jpg', '.png'].includes(
                                          fileRegex.exec(inputs.supportDocsBankName3)[0],
                                        )
                                      ) {
                                        setShowModal3(true);
                                      }
                                    }}
                                  >
                                    {inputs.supportDocsBankName3}
                                  </a>
                                  &nbsp;
                                  <FeatherIcon
                                    id="clearFile3"
                                    icon="x"
                                    size={14}
                                    name="clearFile3"
                                    style={{ cursor: 'pointer' }}
                                    onClick={setInputs}
                                    className="fe-icon"
                                  />
                                </div>
                              )}
                              {errors.supportDocsBank3 && (
                                <div className="invalid-feedback-file text-left">
                                  {errors.supportDocsBank3}
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                        {country == 'Italy' && (
                          <>
                            <div className="col-12 col-lg-4">
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('Please Attach other supporting Documents 1')}
                                </label>
                                {!inputs.supportDocsBank4 && (
                                  <input
                                    type="file"
                                    id="upload-support-docs"
                                    style={{ color: 'transparent' }}
                                    accept="image/png, image/jpeg, .doc, .docx, application/msword, .msg, .pdf, .zip"
                                    name="supportDocsBank4"
                                    onChange={(e, action) => {
                                      FileSize(e, action);
                                      setInputs(e);
                                    }}
                                    disabled={isDisableFields || isDisable}
                                  />
                                )}
                                {inputs.supportDocsBank4 && (
                                  <div className="form-control-textfield text-left">
                                    <a
                                      href=""
                                      onClick={(e) => {
                                        e.preventDefault();
                                        if (
                                          ['.jpeg', '.jpg', '.png'].includes(
                                            fileRegex.exec(inputs.supportDocsBankName4)[0],
                                          )
                                        ) {
                                          setShowModal4(true);
                                        }
                                      }}
                                    >
                                      {inputs.supportDocsBankName4}
                                    </a>
                                    &nbsp;
                                    <FeatherIcon
                                      id="clearFile4"
                                      icon="x"
                                      size={14}
                                      name="clearFile4"
                                      style={{ cursor: 'pointer' }}
                                      onClick={setInputs}
                                      className="fe-icon"
                                    />
                                  </div>
                                )}
                                {errors.supportDocsBank4 && (
                                  <div className="invalid-feedback-file text-left">
                                    {errors.supportDocsBank4}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-12 col-lg-4">
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('Please Attach other supporting Documents 2')}
                                </label>
                                {!inputs.supportDocsBank5 && (
                                  <input
                                    type="file"
                                    id="upload-support-docs"
                                    style={{ color: 'transparent' }}
                                    accept="image/png, image/jpeg, .doc, .docx, application/msword, .msg, .pdf, .zip"
                                    name="supportDocsBank5"
                                    onChange={(e, action) => {
                                      FileSize(e, action);
                                      setInputs(e);
                                    }}
                                    disabled={isDisableFields || isDisable}
                                  />
                                )}
                                {inputs.supportDocsBank5 && (
                                  <div className="form-control-textfield text-left">
                                    <a
                                      href=""
                                      onClick={(e) => {
                                        e.preventDefault();
                                        if (
                                          ['.jpeg', '.jpg', '.png'].includes(
                                            fileRegex.exec(inputs.supportDocsBankName5)[0],
                                          )
                                        ) {
                                          setShowModal5(true);
                                        }
                                      }}
                                    >
                                      {inputs.supportDocsBankName5}
                                    </a>
                                    &nbsp;
                                    <FeatherIcon
                                      id="clearFile5"
                                      icon="x"
                                      size={14}
                                      name="clearFile5"
                                      style={{ cursor: 'pointer' }}
                                      onClick={setInputs}
                                      className="fe-icon"
                                    />
                                  </div>
                                )}
                                {errors.supportDocsBank5 && (
                                  <div className="invalid-feedback-file text-left">
                                    {errors.supportDocsBank5}
                                  </div>
                                )}
                              </div>
                            </div>
                            {/* Attachment goes here */}
                            <div className="col-12 col-lg-4">
                              <div className="form-group">
                                <label className="form-label text-left">
                                  {getLang('Please Attach other supporting Documents 3')}
                                </label>
                                {!inputs.supportDocsBank6 && (
                                  <input
                                    type="file"
                                    id="upload-support-docs"
                                    style={{ color: 'transparent' }}
                                    accept="image/png, image/jpeg, .doc, .docx, application/msword, .msg, .pdf, .zip"
                                    name="supportDocsBank6"
                                    onChange={(e, action) => {
                                      FileSize(e, action);
                                      setInputs(e);
                                    }}
                                    disabled={isDisableFields || isDisable}
                                  />
                                )}
                                {inputs.supportDocsBank6 && (
                                  <div className="form-control-textfield text-left">
                                    <a
                                      href=""
                                      onClick={(e) => {
                                        e.preventDefault();
                                        if (
                                          ['.jpeg', '.jpg', '.png'].includes(
                                            fileRegex.exec(inputs.supportDocsBankName6)[0],
                                          )
                                        ) {
                                          setShowModal6(true);
                                        }
                                      }}
                                    >
                                      {inputs.supportDocsBankName6}
                                    </a>
                                    &nbsp;
                                    <FeatherIcon
                                      id="clearFile6"
                                      icon="x"
                                      size={14}
                                      name="clearFile6"
                                      style={{ cursor: 'pointer' }}
                                      onClick={setInputs}
                                      className="fe-icon"
                                    />
                                  </div>
                                )}
                                {errors.supportDocsBank6 && (
                                  <div className="invalid-feedback-file text-left">
                                    {errors.supportDocsBank6}
                                  </div>
                                )}
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
        </Row>
        <div className="card-footer">
          <div className="row">
            <div className="col-auto col-md-auto text-left">
              {!isDisable && (
                <Button variant="outline-warning" onClick={props.saveDraft}>
                  Save Draft
                </Button>
              )}
            </div>
            <div className="col col-md text-left justify-content">{props.draftMsg}</div>
            <div className="col-auto col-md-auto text-right">
              <Link
                data-link="shipto"
                data-back="true"
                className="btn btn-warning btn-block"
                style={{ textDecoration: 'none' }}
                onClick={handleSubmit}
              >
                Back
              </Link>
            </div>
            <div className="col-auto col-md-auto text-right">
              <Link
                data-link="soldto"
                className={`btn btn-${
                  Object.keys(validateFields(inputs)).length == 0 || isDisable
                    ? 'danger'
                    : 'secondary'
                } btn-block`}
                style={{ textDecoration: 'none' }}
                onClick={handleSubmit}
              >
                Next
              </Link>
            </div>
          </div>
        </div>
      </form>
    )
  );
};

export default CreateCustPayer;
